<template>
	<div class="m-con u-p-t-60">
		<div class="u-container u-flex u-col-top">
			<div class="m-left">
				<!--<div class="m-left-info">
					<div class="u-flex u-row-center">
						<div class="m-left-info-img u-m-t-22">
							<img v-if="!user_info.image" src="../assets/img/common/head-logo.png" />
							<img v-else :src="user_info.image" />
						</div>
					</div>
					<p class="m-left-info-font1">欢迎您：{{user_info.username}}</p>
					<div class="u-flex-col">
						<el-upload
						  class="upload-demo"
						  ref="exportUpload"
						  :limit="1"
						  name="image"
						  :multiple="false"
						  :show-file-list="false"
						  :on-success="uploadSuccess"
						  :on-error="uploadError"
						  :action="baseUrl+'api/uploadImg'"
						  :data="{token:paramsUploadToken}">
						  <div class="m-left-info-btn u-m-t-22">上传图片</div>
						</el-upload>
						<div class="m-left-info-btn m-left-info-btn-extend u-m-t-12" @click="selectNav('/center/info')">修改信息</div>
					</div>
				</div>-->
				<ul class="">
					<li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('/center/index')}" @click="selectNav('/center/index')">个人中心</div>
					</li>
					<li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('/center/info')}" @click="selectNav('/center/info')">修改个人信息</div>
					</li>
					<li v-for="(item,index) in typeList"  :key=index>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('center/study',{typeId:item.id},2)}" @click="selectNav('center/study',{typeId:item.id},2)">{{item.name}}</div>
					</li>
					<!-- <li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('/center/files')}" @click="selectNav('/center/files')">课程讲义</div>
					</li> -->
					<!--<li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('center/study',{typeId:3},2)}" @click="selectNav('center/study',{typeId:3},2)">设备学习</div>
					</li>
					<li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('center/study',{typeId:4},2)}" @click="selectNav('center/study',{typeId:4},2)">一建学习</div>
					</li>
					<li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('center/study',{typeId:5},2)}" @click="selectNav('center/study',{typeId:5},2)">注安学习</div>
					</li> -->
					<li>
						<div class="li-group1 u-line-1" @click="answerShow = !answerShow">我的答题</div>
						<collapse>
							<div class="li-group-box" v-show="answerShow"> 
								<!-- <p  class="li-group3 u-line-1" :class="{'li-group-active':checkClass('/center/record2')}" @click="selectNav('/center/record2')">答题练习</p> -->
								<p  class="li-group3 u-line-1" :class="{'li-group-active':checkClass('/center/fail')}" @click.stop="selectNav('/center/fail')">我的错题</p>
								<p  class="li-group3 u-line-1" :class="{'li-group-active':checkClass('/center/collect')}" @click.stop="selectNav('/center/collect')">我的收藏</p>
							</div>
						</collapse>
					</li>
					<li>
						<div class="li-group1 u-line-1" @click="kaoshiShow = !kaoshiShow">我的考试</div>
						<collapse>
							<div class="li-group-box" v-show="kaoshiShow"> 
								<p  class="li-group3 u-line-1" :class="{'li-group-active':checkClass('center/record',{type:2},2)}" @click="selectNav('center/record',{type:2},2)">考试记录</p>
							</div>
						</collapse>
					</li>
					<!-- <li>
						<div class="li-group1 u-line-1" :class="{'li-group-active':checkClass('/center/case')}" @click="selectNav('/center/case')">案例库</div>
					</li> -->
				</ul>
			</div>
			
			<div class="m-right">
				<router-view :key="key" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import collapse from "../assets/collapse.js"
	import {to_link,to_redirect} from "../router/api.js"
	import {updataUserImage} from "@/api/common.js"
	import {getTopCate} from "@/api/index.js"
	import {getCateForUser} from "@/api/center.js"
	import CONFIG from "@/utils/config"
	export default {
		components:{
			collapse
		},
		data() {
			return {
				baseUrl:CONFIG.baseUrl,
				answerShow:false,
				kaoshiShow:false,
				paramsUploadToken:'',
				typeList:[]
			}
		},
		computed: {
			key() {
				return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
			},
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		mounted() {
			this.paramsUploadToken = window.localStorage.getItem('token')
			this.getType()
			console.log('初始化')
		},
		methods:{
			to_link,
			async getType(){
				let res = await getCateForUser({})
				if(res.code == 200){
					this.typeList = res.data
				}else{
					this.$message.error(res.message)
				}
			},
			checkClass(path,params,type=1){
				let pagePath = this.$route.fullPath
				if(type == 1){
					return pagePath == path
				}else{
					let page2= ''
					for(let i in params){
						page2 = page2 + '/' +params[i]
					}
					return pagePath == `/${path.toLowerCase()}${page2}`
				}
			},
			async uploadSuccess(e){
				console.log(e);
				if(e.code == 200){
					let res = await updataUserImage({image:e.data.path})
					this.$refs.exportUpload.clearFiles()
					if(res.code == 200){
						this.$store.dispatch('vuex_get_user_info')
					}else{
						this.$message.error(res.message)
					}
				}else{
					this.$message.error({
						message:e.message,
						duration:1500
					})
				}
			},
			uploadError(){
				this.$message.error({
					message:'上传失败',
					duration:1500
				})
			},
			selectNav(path, params = {},type=1) {
				let pagePath = this.$route.fullPath
				let routeParams = this.$route.query
				let page2= ''
				if(type == 2){
					for(let i in params){
						page2 = page2 + '/' +params[i]
					}
				}

				if ((type == 1 && pagePath == path ) || (type == 2 && pagePath == `/${path.toLowerCase()}${page2}`)) return false;
				to_redirect(path, params,type)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.m-con {
		width: 100%;
		min-height: 600px;

		padding-bottom: 100px;
    background: linear-gradient(to bottom,#11a6ff,#2bb0ff,#5bc2ff,#91d6ff,#cbecff,#fdffff) !important;
	}

	.m-head-bg {
		width: 100%;
		min-width: 1200px;
		height: 430px;
		background: url(../assets/img/common/m-head-bg.png) no-repeat;
		background-size: cover;
		background-position: center 0;
	}

	.m-left {
		width: 200px;
		background: #fff;
		
		.m-left-info{
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 200px;
			height: 260px;
			background: #1b87ef;
			.m-left-info-img{
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background: #fff;
				img{
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
			.m-left-info-font1{
				font-size: 14px;
				color: #fff;
				line-height: 14px;
				text-align: center;
				margin-top: 16px;
			}
			.m-left-info-btn{
				width: 130px;
				height: 22px;
				border-radius: 11px;
				background: #F8A310;
				text-align: center;
				line-height: 22px;
				font-size: 13px;
				color: #fff;
				cursor: pointer;
			}
			.m-left-info-btn-extend{
				background: #fff;
				color: #262626;
			}
		}

		ul {
			display: flex;
			flex-direction: column;

			li {
				display: flex;
				flex-direction: column;
				width: 200px;
				border-bottom: 1px solid #DDDDDD;
				.li-group1{
					padding-left: 20px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					height: 45px;
					line-height: 45px;
					cursor: pointer;
					&:hover{
						background: #1b87ef;
						color: #fff;
					}
				}
				.li-group-active{
					background: #1b87ef;
					color: #fff!important;
				}
				.li-group-box{
					display: flex;
					flex-direction: column;
					.li-group2{
						padding-left: 20px;
						height: 40px;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #1b87ef;
						line-height: 40px;
					}
					.li-group3{
						padding-left: 20px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						height: 40px;
						line-height: 40px;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	.m-right{
		width: 960px;
		background: #fff;
		margin-left: 38px;
	}
</style>
